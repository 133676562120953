import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Controller } from '@hotwired/stimulus'
import { focusableSelector, isVisible } from '../../utils/application'

export default class extends Controller {
  static classes = ['current']
  static values = {
    current: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
    scroll: { type: Boolean, default: true },
    duration: { type: Number, default: 1 },
    easing: { type: String, default: 'power0' },
    offset: { type: Number, default: 20 },
    targetId: String
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

    this.config = {
      headerClass: 'js-page-header',
      stickyNavigationClass: 'sticky-navigation'
    }
  }

  connect() {
    if (this.currentValue === true) {
      this.checkCurrent()
    }

    if (window.location.hash === this.targetId) {
      this.scrollToTarget()
    }
  }

  /* ----- ACTION EVENTS ----- */

  onAjaxComplete(event) {
    if (this.focusValue) this.focusOnTarget()
    if (this.scrollValue) this.scrollToTarget()
  }

  onClick(event) {
    event.preventDefault()

    if (this.focusValue) this.focusOnTarget()
    if (this.scrollValue) this.scrollToTarget()
  }

  onKeyUp(event) {
    event.preventDefault()

    if (event.keyCode === 13 || event.keyCode === 32) {
      if (this.focusValue) this.focusOnTarget()
      if (this.scrollValue) this.scrollToTarget()
    }
  }

  /* ----- FUNCTIONS ----- */

  focusOnTarget(target = this.target) {
    const firstFocusableItem = target.querySelector(focusableSelector)

    if (firstFocusableItem) firstFocusableItem.focus()
  }

  scrollToTarget(targetId = this.targetId) {
    let offset = this.offsetValue
    const header = document.querySelector(`.${this.config.headerClass}`)
    const stickyNavigation = document.querySelector(`.${this.config.stickyNavigationClass}`)

    if (header && isVisible(header)) offset += header.offsetHeight
    if (stickyNavigation && isVisible(stickyNavigation)) offset += stickyNavigation.offsetHeight

    gsap.to(window, {
      duration: this.durationValue,
      scrollTo: {
        y: targetId,
        offsetY: offset,
        ease: this.easingValue
      }
    })
  }

  checkCurrent() {
    ScrollTrigger.create({
      trigger: this.target,
      start: `top center`,
      end: `bottom center`,
      onToggle: (self) => {
        self.refresh()
        this.element.classList.toggle(this.hasCurrentClass ? this.currentClass : 'is-active')
      }
    })
  }

  /* ----- GETTERS / SETTERS ----- */

  get targetId() {
    return this.hasTargetIdValue ? this.targetIdValue : this.element.hash
  }

  get target() {
    return document.querySelector(this.targetId)
  }
}
