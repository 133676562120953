import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { post } from 'scripts/utils/ajax'
import { serialize } from 'scripts/utils/form'

export default class extends Controller {
  static debounces = [{ name: 'updateSummary', wait: 600 }]
  static values = { summaryUrl: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    useDebounce(this)
  }

  /* ----- ACTION EVENTS ----- */

  onInputKeyup(event) {
    this.loadSummary()
  }

  onAssociationRemove(event) {
    this.loadSummary()
  }

  /* ----- FUNCTIONS ----- */

  load(active = true) {
    if (active) {
      if (!this.loading) {
        this.submitButton.disabled = true
        this.submitButton.classList.add('main-button--disabled')
        this.submitButton.innerHTML = '<i class="fas fa-spinner fa-spin main-button__icon"></i>'
      }

      this.loading = true
    } else {
      this.loading = false
    }
  }

  loadSummary() {
    this.load()
    this.updateSummary()
  }

  async updateSummary() {
    const result = await post(this.summaryUrlValue, serialize(this.element), 'html')
    const element = result.body.firstChild

    document.getElementById(element.id).replaceWith(element)
    this.load(false)
  }

  /* ----- GETTERS / SETTERS ----- */

  get submitButton() {
    return document.querySelector(`[type="submit"][form="${this.element.id}"]`)
  }
}
