import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['animatedItem', 'stickyItem']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    gsap.registerPlugin(ScrollTrigger)

    this.config = {
      isStickyClass: 'is-sticky'
    }
  }

  connect() {
    if (this.hasAnimatedItemTarget) {
      ScrollTrigger.matchMedia({
        '(prefers-reduced-motion: no-preference)': () => {
          this.animatedItemTargets.forEach(item => {
            this.hide(item)

            ScrollTrigger.create({
              trigger: item,
              start: 'top bottom-=5rem',
              end: 'bottom top',
              once: true,
              onEnter: () => {
                this.animate(item)
              }
            })
          })
        }
      })
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  hide(item) {
    gsap.to(item, { y: 200, opacity: 0 })
  }

  animate(item, direction = 1) {
    gsap.fromTo(item, { y: direction * 200, opacity: 0 }, { y: 0, opacity: 1 })
  }

  /* ----- GETTERS / SETTERS ----- */
}
