import { Controller } from '@hotwired/stimulus'
import { get } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['container']
  static values = { url: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onDateChange({ detail: { date } }) {
    this.replaceForm(date)
  }

  /* ----- FUNCTIONS ----- */

  async replaceForm(date) {
    const result = await get(this.urlValue, { desired_on: date }, 'html')
    this.containerTarget.innerHTML = result.body.innerHTML
  }

  /* ----- GETTERS / SETTERS ----- */
}
