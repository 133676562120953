const serialize = (form, type) => {
  const data = Array.from(form.elements)
    .filter(element => element.name && !element.disabled && !['button', 'file', 'submit', 'reset'].includes(element.type))
    .reduce((obj, element) => {
      if (element.type === 'select-multiple') {
        // Get all selections of multi select
        obj[element.name] = Array.from(element.options)
          .filter(option => option.selected)
          .map(option => option.value)
      } else if (element.type === 'hidden') {
        // Don't keep value if has other inputs with same name
        if (form.querySelectorAll(`[name="${element.name}"]`).length === 1) {
          obj[element.name] = element.value
        }
      } else if (!['checkbox', 'radio'].includes(element.type) || element.checked) {
        // Keep only checked checkboxes/radios
        obj[element.name] = element.value
      }

      return obj
    }, {})

  if (type === 'Object') return data
  if (type === 'JSON') return JSON.stringify(data)
  if (type === 'Array') return Object.entries(data)

  const params = new URLSearchParams(data)

  if (type === 'URLSearchParams') return params
  return params.toString()
}

export { serialize }
