import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'muteButton', 'poster', 'video']
  static values = {
    remove: Boolean
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      isMutedClass: 'is-muted'
    }
  }

  disconnect() {
    if (this.hasVideoTarget && this.removeValue) this.videoTarget.remove()
  }

  videoTargetConnected(element) {
    let videoSourceLoaded = false

    element.querySelectorAll('source').forEach(source => {
      if (!videoSourceLoaded && source.dataset.src && (!source.media || window.matchMedia(source.media).matches)) {
        source.src = source.dataset.src
        source.removeAttribute('data-src')
        element.load()
        videoSourceLoaded = true
      }
    })

    if (videoSourceLoaded) {
      element.oncanplaythrough = () => {
        element.removeAttribute('hidden')
        this.contentTarget.classList.add('has-anim')
      }
    } else if (this.hasPosterTarget) {
      element.remove()
      this.posterTarget.classList.remove('is-hidden-sm+')

      if (this.hasMuteButtonTarget) {
        this.muteButtonTarget.remove()
      }
    }
  }

  /* ----- ACTION EVENTS ----- */

  handleToggleMute(event) {
    this.toggleMute()
  }

  onLightboxClick(event) {
    this.toggleMute(true)
  }

  /* ----- FUNCTIONS ----- */

  toggleMute(force) {
    if (this.hasMuteButtonTarget) {
      this.muteButtonTarget.classList.toggle(this.config.isMutedClass, force)
    }

    if (this.hasVideoTarget) {
      this.videoTarget.muted = force ?? !this.videoTarget.muted
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
