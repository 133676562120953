import Rails from '@rails/ujs'

import { get } from 'scripts/utils/ajax'

class Confirm {
  init() {
    const allowAction = el => {
      const target = el.dataset.confirm ? el : el.closest('[data-confirm]')

      this.show(target)
      return false
    }

    Rails.delegate(document, '[data-confirm]', 'click', e => {
      if (!allowAction(e.target)) Rails.stopEverything(e)
    })
  }

  async show(target) {
    let data = {}

    if (target.dataset.confirmTitle) {
      data.title = target.dataset.confirmTitle
    }

    if (target.dataset.confirmText) {
      data.text = target.dataset.confirmText
    }

    if (target.dataset.confirmDenyText) {
      data.deny_text = target.dataset.confirmDenyText
    }

    if (target.dataset.confirmAcceptText) {
      data.accept_text = target.dataset.confirmAcceptText
    }

    const result = await get(target.dataset.confirmUrl, data, 'html')

    document.querySelector('#page-modal-wrapper').innerHTML = result.body.innerHTML

    const acceptButton = document.querySelector('[data-confirm-accept]')

    acceptButton.addEventListener('click', () => {
      target.removeAttribute('data-confirm')
      target.click()
      target.dataset.confirm = true
    })
  }
}

const instance = new Confirm()
Object.freeze(instance)

export default instance
