import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.fixScrollPersistance()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  fixScrollPersistance() {
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
  }

  /* ----- GETTERS / SETTERS ----- */
}
