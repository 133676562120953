import { Controller } from '@hotwired/stimulus'
import { toggleBodyLock } from 'scripts/utils/application'
import { breakpoint } from '../../utils/application'

export default class extends Controller {
  static targets = ['list', 'mainBar', 'menu', 'messageBar']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      listSelector: `[data-${this.identifier}-target="list"]`,
      isOpenClass: 'is-open',
      isActiveClass: 'is-active',
      isScrolledClass: 'is-scrolled',
    }
  }

  connect() {
    this.scrolled()
  }

  /* ----- ACTION EVENTS ----- */

  handleShowList(event) {
    if (window.innerWidth > breakpoint('md')) {
      this.toggleList(event.currentTarget, true)
    }
  }

  handleHideList(event) {
    if (window.innerWidth > breakpoint('md')) {
      this.toggleList(event.currentTarget, false)
    }
  }

  handleToggleList(event) {
    if (window.innerWidth <= breakpoint('md')) {
      this.toggleList(event.currentTarget)
    }
  }

  handleToggleMenu(event) {
    this.toggleMenu(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  toggleList(trigger, force) {
    if (this.hasListTarget) {
      const subnav = trigger.querySelector(this.config.listSelector) || trigger.closest(this.config.listSelector) || trigger.nextElementSibling

      subnav.classList.toggle(this.config.isOpenClass, force)
      if (this.hasMainBarTarget) this.mainBarTarget.classList.toggle(this.config.isActiveClass, force)
    }
  }

  toggleMenu(trigger, force) {
    if (this.hasMenuTarget) {
      toggleBodyLock(force)
      trigger.classList.toggle(this.config.isOpenClass, force)
      this.menuTarget.classList.toggle(this.config.isOpenClass, force)
    }
  }

  scrolled() {
    if (window.scrollY > 10) {
      this.element.classList.add(this.config.isScrolledClass)
    } else {
      this.element.classList.remove(this.config.isScrolledClass)
    }

    if (this.hasMessageBarTarget) {
      if (window.innerWidth <= breakpoint('sm') && window.scrollY > 160) {
        this.messageBarTarget.classList.add('is-hidden')
      } else if (window.innerWidth > breakpoint('sm') && window.scrollY > 260) {
        this.messageBarTarget.classList.add('is-hidden')
      } else {
        this.messageBarTarget.classList.remove('is-hidden')
      }
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
