import { Controller } from '@hotwired/stimulus'
import { wrap } from 'scripts/utils/application'

export default class extends Controller {
  connect() {
    this.wrapTables()
  }

  wrapTables() {
    this.element.querySelectorAll('table').forEach(table => {
      const wrapper = document.createElement('div')

      wrapper.style.overflowX = 'auto'
      wrap(table, wrapper)
    })
  }
}
