import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['paymentMethodRadio']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */
  onSubmit(event) {
    event.preventDefault()
    const paymentMethodSelected = this.paymentMethodRadioTargets.find(element => element.checked)
    const paymentMethodForm = this.element.querySelector("[data-checkout--payment-target='" + paymentMethodSelected.value + "']")

    paymentMethodForm.submit()
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
