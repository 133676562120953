import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: { type: String, default: 'mtcaptcha-field' }
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    if (typeof mtcaptcha !== 'undefined') {
      mtcaptcha.renderUI()
    }
  }

  disconnect() {
    if (typeof mtcaptcha !== 'undefined') {
      mtcaptcha.remove()
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */

}
