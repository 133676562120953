import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'
import { breakpoint } from '../../utils/application'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

export default class extends Controller {
  static targets = ['item', 'image']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      selector: `[data-${this.identifier}-target="item"]`
    }
  }

  connect() {
    useWindowResize(this)
  }

  /* ----- FUNCTIONS ----- */

  windowResize({ width }) {
    if (width > breakpoint('sm')) {
      this.initGallery()
    } else if (this.gallery && this.isActive) {
      this.gallery.destroy()
      this.isActive = false
    }
  }

  initGallery() {
    if (this.hasItemTarget && !this.isActive) {
      if (!this.gallery) {
        if (this.hasImageTarget) {
          imagesLoaded(this.imageTargets, () => {
            this.gallery = new Masonry(this.element, this.galleryConfig)
          })
        } else {
          this.gallery = new Masonry(this.element, this.galleryConfig)
        }
      } else {
        this.gallery.layout()
      }

      this.isActive = true
    }
  }

  /* ----- GETTERS / SETTERS ----- */

  get galleryConfig() {
    return {
      itemSelector: this.config.selector,
      fitWidth: true,
      gutter: 24
    }
  }
}
