import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static values = { url: String }
  isLoaded = false
  options = { rootMargin: '200px' }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    useIntersection(this, this.options)
  }

  /* ----- ACTION EVENTS ----- */

  appear(entry) {
    if (this.hasUrlValue && !this.isLoaded) {
      this.element.style.backgroundImage = `url("${this.urlValue}")`
      this.isLoaded = true
    }
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
