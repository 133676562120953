const StrReplaceAll = (string = null, searchValue = null, replaceValue = null) => {
  if (!string || !searchValue || !replaceValue || typeof string != 'string' || typeof string != searchValue || typeof string != replaceValue) {
    return '';
  }

  return string.split(searchValue).join(replaceValue)
}

const breakpoint = key => parseInt(getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-${key}`))

const currentBreakpointKey = StrReplaceAll(getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-current-key`), '"', '').trim()

const container = columns => parseInt(getComputedStyle(document.documentElement).getPropertyValue(`--container-${columns}`))

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const insertAfter = (el, reference) => {
  reference.parentNode.insertBefore(el, reference)
  reference.parentNode.insertBefore(reference, el)
}

const isVisible = el => {
  if (!el) return false
  if (!el.offsetWidth && !el.offsetHeight) return false
  if (!el.getClientRects().length) return false
  if (el.display === 'none') return false
  if (el.style.visibility === 'hidden') return false
  return true
}

const language = document.documentElement.lang

const parseDOM = (data, type = 'text/html') => new DOMParser().parseFromString(data, type)

const pxToRem = value => value / 10

const randomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min)) + min
}

const remToPx = value => value * 10

const serializeDOM = data => new XMLSerializer().serializeToString(data)

const toggleBodyLock = state => document.body.classList.toggle('is-locked', state)

const viewportOffset = el => {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

const wrap = (el, wrapper = document.createElement('div')) => {
  el.parentNode.insertBefore(wrapper, el)
  wrapper.appendChild(el)
}

export { breakpoint, container, currentBreakpointKey, focusableSelector, insertAfter, isVisible, language, parseDOM, pxToRem, randomInt, remToPx, serializeDOM, toggleBodyLock, viewportOffset, wrap }
