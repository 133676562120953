import BigPicture from 'bigpicture'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    imageUrl: String,
    videoUrl: String
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  handleOpen(event) {
    this.open()
  }

  /* ----- FUNCTIONS ----- */

  open() {
    this.bp = BigPicture({
      el: this.element,
      imgSrc: this.hasImageUrlValue ? this.imageUrlValue : null,
      vidSrc: this.hasVideoUrlValue ? this.videoUrlValue : null
    })
  }

  /* ----- GETTERS / SETTERS ----- */
}
