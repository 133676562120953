import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'panel']
  static values = { auto: { type: Boolean, default: true } }
  static classes = ['buttonDisplay', 'panelDisplay']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.panelClass = this.hasPanelDisplayClass ? this.panelDisplayClass : 'is-hidden'
    this.buttonClass = this.hasButtonDisplayClass ? this.buttonDisplayClass : 'is-inactive'

    if (this.hasPanelTarget) {
      if (this.autoValue && !this.panelTargets.some(panel => !panel.classList.contains(this.panelClass)) || this.panelTargets.every(panel => !panel.classList.contains(this.panelClass))) {
        this.displayPanel(this.panelTargets[0].id)
      } else {
        const activePanel = this.panelTargets.find(panel => !panel.classList.contains(this.panelClass))
        if (activePanel) {
          this.activeButton(activePanel.id)
        }
      }
    }
  }

  /* ----- ACTION EVENTS ----- */

  onButtonClick(event) {
    this.displayPanel(event.params.id)
  }

  onRadioChange(event) {
    this.displayPanel(event.params.id)
  }

  /* ----- FUNCTIONS ----- */

  displayPanel(id) {
    this.panelTargets.forEach(panel => panel.classList.toggle(this.panelClass, panel.id !== id))
    this.activeButton(id)
  }

  activeButton(id) {
    this.buttonTargets.forEach(button => button.classList.toggle(this.buttonClass, button.dataset.tabsIdParam !== id))
  }

  /* ----- GETTERS / SETTERS ----- */
}
