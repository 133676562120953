import { Controller } from '@hotwired/stimulus'
import { useLazyLoad } from 'stimulus-use'

export default class extends Controller {
  options = { rootMargin: '200px' }

  connect() {
    useLazyLoad(this)
  }
}
