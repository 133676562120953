import flatpickr from 'flatpickr'
import { Controller } from '@hotwired/stimulus'

// Locales
import { French } from 'flatpickr/dist/l10n/fr.js'

import { language } from 'scripts/utils/application'

export default class extends Controller {
  static targets = ['input']
  static values = { options: Object, type: { type: String, default: 'datetime' } }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  disconnect() {
    this.picker.destroy()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  setup() {
    this.picker = flatpickr(this.element, this.options)
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    let options = this.defaultOptions

    if (language in this.locales) {
      options['locale'] = this.locale
    }

    if (this.typeValue == 'date') {
      options = { ...options, ...this.dateOptions }
    } else if (this.typeValue == 'time') {
      options = { ...options, ...this.timeOptions }
    } else {
      options = { ...options, ...this.datetimeOptions }
    }

    return {
      ...options,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {
      wrap: true,
      altInput: true,
      allowInput: true,
      disableMobile: true
    }
  }

  get datetimeOptions() {
    let options = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      altFormat: 'Y/m/d H:i',
    }

    const localeOptions = {
      fr: {
        altFormat: 'd/m/Y H:i',
      }
    }

    if (language in localeOptions) {
      options = { ...options, ...localeOptions[language] }
    }

    return options
  }

  get dateOptions() {
    let options = {
      dateFormat: 'Y-m-d',
      altFormat: 'Y/m/d',
    }

    const localeOptions = {
      fr: {
        altFormat: 'd/m/Y',
      }
    }

    if (language in localeOptions) {
      options = { ...options, ...localeOptions[language] }
    }

    return options
  }

  get timeOptions() {
    let options = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      altFormat: 'H:i',
    }

    const localeOptions = {}

    if (language in localeOptions) {
      options = { ...options, ...localeOptions[language] }
    }

    return options
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      fr: French
    }
  }
}
