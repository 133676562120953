import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'
import { pxToRem } from 'scripts/utils/application'

export default class extends Controller {
  static targets = ['item']
  static values = {
    prefix: { type: String, default: 'equalizer' }
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    useWindowResize(this)

    this.setMaxSizes()
  }

  /* ----- ACTION EVENTS ----- */

  windowResize({ width, height, event }) {
    this.setMaxSizes()
  }

  /* ----- FUNCTIONS ----- */

  setMaxSizes() {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(item => {
        item.style.setProperty(`--${this.prefixValue}-height`, 'auto')
        item.style.setProperty(`--${this.prefixValue}-width`, 'auto')
      })

      const maxHeight = Math.max(...this.itemTargets.map(item => item.offsetHeight), 0)
      const maxWidth = Math.max(...this.itemTargets.map(item => item.offsetWidth), 0)

      this.itemTargets.forEach(item => {
        item.style.setProperty(`--${this.prefixValue}-height`, `${pxToRem(maxHeight)}rem`)
        item.style.setProperty(`--${this.prefixValue}-width`, `${pxToRem(maxWidth)}rem`)
      })
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
