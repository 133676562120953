import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['action', 'input', 'value']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  /* ----- ACTION EVENTS ----- */

  onActionClick(event) {
    if (event.params.step === '+') {
      this.increase(this.step)
    } else if (event.params.step === '-') {
      this.decrease(this.step)
    }
  }

  onInputChange(event) {
    this.valueTarget.textContent = this.value
  }

  /* ----- FUNCTIONS ----- */

  increase(step) {
    this.value = this.value + step
  }

  decrease(step) {
    this.value = this.value - step
  }

  /* ----- GETTERS / SETTERS ----- */

  get min() {
    return parseInt(this.inputTarget.min)
  }

  get max() {
    return parseInt(this.inputTarget.max)
  }

  get step() {
    return parseInt(this.inputTarget.step)
  }

  get value() {
    return parseInt(this.inputTarget.value)
  }

  set value(value) {
    if ((isNaN(this.min) || value >= this.min) && (isNaN(this.max) || value <= this.max)) {
      this.inputTarget.value = value
      this.inputTarget.dispatchEvent(new Event('change'))
    }
  }
}
