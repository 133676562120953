import Swiper, { Navigation, Pagination } from 'swiper'
import { Controller } from '@hotwired/stimulus'
import { breakpoint } from '../../utils/application'
import { useWindowResize } from 'stimulus-use'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      bigSliderClass: 'swiper-slider--big',
      smallSliderClass: 'swiper-slider--small',
      gallerySliderClass: 'swiper-slider--gallery',
      packSliderClass: 'swiper-slider--pack',
    }
  }

  connect() {
    Swiper.use([Navigation, Pagination])

    this.initSlider(this.sliderConfig)
    useWindowResize(this)
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  windowResize({ width }) {
    this.handleGallerySlider(width)
  }

  initSlider(config = {}) {
    this.slider = new Swiper(this.element, config)
  }

  handleGallerySlider(width) {
    if (this.element.classList.contains(this.config.gallerySliderClass)) {
      if (width <= breakpoint('sm')) {
        if (!this.slider) this.initSlider(this.sliderConfig)
      } else if (this.slider) {
        this.slider.destroy(false)
        this.slider = null
      }
    }
  }

  /* ----- GETTERS / SETTERS ----- */

  get sliderConfig() {
    let config = {}

    if (this.element.classList.contains(this.config.bigSliderClass)) {
      config = this.bigSliderConfig
    } else if (this.element.classList.contains(this.config.smallSliderClass)) {
      config = this.smallSliderConfig
    } else if (this.element.classList.contains(this.config.gallerySliderClass)) {
      config = this.gallerySliderConfig
    } else if (this.element.classList.contains(this.config.packSliderClass)) {
      config = this.packSliderConfig
    }

    return {
      slidesPerView: parseInt(this.element.dataset.slidesPerView),
      speed: 500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      ...config
    }
  }

  get bigSliderConfig() {
    return {
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        init: (swiper) => {
          this.element.style = `--swiper-slide-content-trans: ${swiper.wrapperEl.clientWidth / 20}rem;`
        }
      }
    }
  }

  get smallSliderConfig() {
    return {
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 16,
          pagination: {
            el: '.swiper-pagination',
          },
        },
        769: {
          spaceBetween: 16,
        },
        1025: {
          pagination: false,
          centeredSlides: true,
          centeredSlidesBounds: true,
          spaceBetween: 81
        }
      }
    }
  }

  get gallerySliderConfig() {
    return {
      lazy: true,
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        769: {
          init: false
        }
      }
    }
  }

  get packSliderConfig() {
    return {
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 16,
          pagination: {
            el: '.swiper-pagination',
          },
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 24
        },
        1025: {
          pagination: false,
          centeredSlides: true,
          centeredSlidesBounds: true,
          spaceBetween: 32
        }
      }
    }
  }
}
