import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenInput', 'name', 'removeInput']

  onGetNameClick(event) {
    this.getName(event.currentTarget)
  }

  onRemoveClick(event) {
    event.preventDefault()
    this.remove(event)
  }

  onUploadProgress(event) {
    const { progress } = event.detail
    event.currentTarget.closest('.input-group').querySelector('input[type="text"]').style.backgroundImage = `linear-gradient(90deg, rgba(40, 167, 69, .2) ${progress}%, transparent 0%)`
  }

  getName(target) {
    let name = target.files[0].name

    if (this.type === 'multiple') {
      const translation = target.files.length > 1 ? this.locale.selected_files : this.locale.selected_file
      name = target.files.length + ' ' + translation
    }

    this.nameTarget.textContent = name
  }

  remove(event) {
    const parent = event.currentTarget.closest('a')

    if (parent && parent.dataset.attachmentId) {
      parent.nextElementSibling.remove()
      parent.remove()
    } else {
      this.removeInputTarget.disabled = false
      this.nameTarget.textContent = ''
    }
  }

  get type() {
    if (this.hiddenInputTarget.hasAttribute('multiple')) {
      return 'multiple'
    } else {
      return 'single'
    }
  }

  get locale() {
    return {
      selected_file: 'fichier sélectionné',
      selected_files: 'fichiers sélectionnés',
    }
  }
}
